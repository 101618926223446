export const menuItems = [
  {
    isHeadr: true,
    title: "menu",
  },
  {
    title: "Dashboard",
    icon: "heroicons-outline:home",
    link: "/home",
    permission: 'dashboard-view'
  },
  {
    title: "Purchase",
    icon: "heroicons:archive-box",
    link: "/purchase/menu",
    permission: 'purchase-view'
  },
  {
    title: "Inventory",
    icon: "heroicons-outline:briefcase",
    link: "/inventory/menu",
    permission: 'inventory-view'
  },
  {
    title: "Sale",
    icon: "heroicons:shopping-bag",
    link: "/sale/menu",
    permission: 'sale-view'
  },
  {
    title: "Workshop",
    icon: "heroicons-outline:clipboard-document-list",
    link: "/workshop/menu",
    permission: 'workshop-view'
  },
  {
    title: "Unit",
    icon: "heroicons:rocket-launch",
    link: "/unit",
    permission: 'unit-view'
  },
  {
    title: "Invoice",
    icon: "heroicons:ticket",
    link: "/invoice/menu",
    permission: 'invoice-view'
  },
  {
    title: "Finance",
    icon: "heroicons-outline:book-open",
    link: "/finance/menu",
    permission: 'finance-view'
  },
  {
    title: "Approval",
    icon: "heroicons:beaker",
    link: "/approval",
    permission: 'approval-view'
  },
  {
    title: "Setting",
    icon: "heroicons:cog-6-tooth",
    permission: 'setting-view',
    isOpen: true,
    child: [
      {
        childtitle: "State",
        childlink: "/state",
        permission: "state-view",
      },
      {
        childtitle: "City",
        childlink: "/city",
        permission: "city-view",
      },
      {
        childtitle: "Organization",
        childlink: "/organization",
        permission: "organization-view",
      },
      {
        childtitle: "Organization Print",
        childlink: "/organization/print",
        permission: "organization-view",
      },
      {
        childtitle: "Branch",
        childlink: "/branch",
        permission: "branch-view",
      },
      {
        childtitle: "User",
        childlink: "/user",
        permission: "user-view",
      },
      {
        childtitle: "Employee",
        childlink: "/employee",
        permission: "employee-view",
      },
      {
        childtitle: "Vendor",
        childlink: "/vendor",
        permission: "vendor-view",
      },
      {
        childtitle: "Customer",
        childlink: "/customer",
        permission: "customer-view",
      },
      {
        childtitle: "Unit of Measure",
        childlink: "/uom",
        permission: "uom-view",
      },
      {
        childtitle: "Payment Method",
        childlink: "/payment/method",
        permission: "payment-method-view",
      },
      {
        childtitle: "Tax",
        childlink: "/tax",
        permission: "tax-view",
      },
      {
        childtitle: "Currency",
        childlink: "/currency",
        permission: "currency-view",
      },
      {
        childtitle: "Exchange",
        childlink: "/exchange",
        permission: "exchange-view",
      },
      {
        childtitle: "Bank",
        childlink: "/bank",
        permission: "bank-view",
      },
      {
        childtitle: "Setting",
        childlink: "/setting",
        permission: "setting"
      },
      {
        childtitle: "Notification Template",
        childlink: "/notification/template",
        permission: "setting"
      },
      {
        childtitle: "Notification",
        childlink: "/notification",
        permission: "setting"
      }
    ]
  }
  // {
  //   title: "Unit",
  //   icon: "heroicons:rocket-launch",
  //   isOpen: true,
  //   child: [
  //     {
  //       childtitle: "Unit",
  //       childlink: "/unit",
  //       permission: "unit-view"
  //     },
  //     {
  //       childtitle: "Unit Check",
  //       childlink: "/unit/check",
  //       permission: "unit-check-view"
  //     }
  //   ]
  // },
  // {
  //   title: "Inventory",
  //   icon: "heroicons-outline:briefcase",
  //   isOpen: true,
  //   child: [
  //     {
  //       childtitle: "Receipt",
  //       childlink: "/inventory/receipt",
  //       permission: "inventory-receipt-view"
  //     },
  //     {
  //       childtitle: "Delivery",
  //       childlink: "/inventory/delivery",
  //       permission: "inventory-delivery-view"
  //     },
  //     {
  //       childtitle: "Warranty",
  //       childlink: "/inventory/warranty",
  //       permission: "inventory-warranty-view"
  //     },
  //     {
  //       childtitle: "Scrap",
  //       childlink: "/inventory/scrap",
  //       permission: "inventory-scrap-view"
  //     },
  //     {
  //       childtitle: "Summary",
  //       childlink: "/inventory/summary",
  //       permission: null
  //     }
  //   ]
  // },
  // {
  //   title: "Purchase",
  //   icon: "heroicons-outline:briefcase",
  //   isOpen: true,
  //   child: [{
  //     childtitle: "Purchase Order",
  //     childlink: "/purchase/order?view=purchase",
  //     permission: null
  //   }, 
  //   {
  //     childtitle: "Purchase",
  //     childlink: "/purchase",
  //     permission: "purchase-view"
  //   },
  //   {
  //     childtitle: "Shipping",
  //     childlink: "/shipping",
  //     permission: "shipping-view"
  //   },
  //   {
  //     childtitle: "Receipt",
  //     childlink: "/inventory/receipt",
  //     permission: "inventory-receipt-view"
  //   }]
  // },
  // {
  //   title: "Transaction",
  //   icon: "heroicons-outline:clipboard-document-list",
  //   isOpen: true,
  //   child: [
  //     {
  //       childtitle: "Order",
  //       childlink: "/order",
  //       permission: "order-view"
  //     },
  //     {
  //       childtitle: "Purchase",
  //       childlink: "/purchase",
  //       permission: "purchase-view"
  //     },
  //     {
  //       childtitle: "Sale",
  //       childlink: "/sale",
  //       permission: "sale-view"
  //     },
  //     {
  //       childtitle: "Workshop",
  //       childlink: "/workshop",
  //       permission: "workshop-view"
  //     },
  //     {
  //       childtitle: "Payment",
  //       childlink: "/payment",
  //       permission: "payment-view"
  //     },
  //     {
  //       childtitle: "Product Summary",
  //       childlink: "/product/summary",
  //       permission: "product-summary-view"
  //     }
  //   ],
  // },
  // {
  //   title: "Workshop",
  //   icon: "heroicons-outline:swatch",
  //   isOpen: true,
  //   child: [
  //     {
  //       childtitle: "Workshop",
  //       childlink: "/workshop",
  //       permission: "workshop-view"
  //     },
  //     {
  //       childtitle: 'Invoice',
  //       childlink: '/invoice',
  //       permission: null
  //     },
  //     {
  //       childtitle: "Monitoring",
  //       childlink: "/workshop/monitoring",
  //       permission: "workshop-monitoring-view"
  //     }
  //   ]
  // },
  // {
  //   title: "Finance",
  //   icon: "heroicons-outline:book-open",
  //   isOpen: true,
  //   child: [
  //     {
  //       childtitle: "Chart Of Account",
  //       childlink: "/finance/chart-of-account",
  //       permission: "finance-coa-view"
  //     },
  //     {
  //       childtitle: "Journal",
  //       childlink: "/finance/journal",
  //       permission: "finance-journal-view"
  //     },
  //     {
  //       childtitle: "Ledger",
  //       childlink: "/finance/ledger",
  //       permission: "finance-ledger-view"
  //     },
  //     {
  //       childtitle: "Profit & Loss",
  //       childlink: "/finance/profit-loss",
  //       permission: "finance-profit-loss-view"
  //     },
  //     {
  //       childtitle: "Trial Balance",
  //       childlink: "/finance/trial-balance",
  //       permission: "finance-trial-balance-view"
  //     },
  //     {
  //       childtitle: "Bank",
  //       childlink: "/finance/bank?type=bank",
  //       permission: "finance-cash-bank-view"
  //     },
  //     {
  //       childtitle: "Cash",
  //       childlink: "/finance/cash?type=cash",
  //       permission: "finance-cash-bank-view"
  //     },
  //     {
  //       childtitle: "Template",
  //       childlink: "/finance/journal-template",
  //       permission: null,
  //     }
  //   ]
  // }
];

export const menuHeader = [{
  header: "Inventory",
  submenu: [{
    title: 'Inventory',
    child: [{
      childtitle: "Receive",
      childlink: "/inventory/receipt",
      permission: "receive-menu",
      gridmenu: {
        label: "Receive",
        icon: "heroicons-outline:receipt-percent",
        permission: 'receive-grid-menu'
      },
    }, {
      childtitle: "Delivery",
      childlink: "/inventory/delivery",
      permission: "delivery-menu",      
      gridmenu: {
        label: "Delivery",
        icon: "heroicons-outline:truck",
        permission: 'delivery-grid-menu'
      },

    }, {
      childtitle: "Adjust",
      childlink: "/inventory/adjust",
      permission: "adjust-menu",
      gridmenu: {
        label: "Adjust",
        icon: "heroicons-outline:document-chart-bar",
        permission: 'adjust-grid-menu'
      },
    },
    // {
    //   childtitle: "Warranty",
    //   childlink: "/inventory/warranty",
    //   permission: "warranty-menu",
    //   gridmenu: {
    //     label: "Warranty",
    //     icon: "heroicons-outline:document-check",
    //     permission: 'warranty-grid-menu'
    //   },
    // },
    // {
    //   childtitle: "Scrap",
    //   childlink: "/inventory/scrap",
    //   permission: "scrap-menu",
    //   gridmenu: {
    //     label: "Scrap",
    //     icon: "heroicons-outline:puzzle-piece",
    //     permission: 'scrap-grid-menu'
    //   },
    // },
    {
      childtitle: "Shipping",
      childlink: "/shipping",
      permission: "shipping-menu",
      gridmenu: {
        label: "Shipping",
        icon: "heroicons-outline:gift",
        permission: 'shipping-grid-menu'
      },
    }, {
      childtitle: "Order",
      childlink: "/inventory/order?view=inventory",
      permission: "purchase-order-menu",
      gridmenu: {
        label: "Order",
        icon: "heroicons-outline:gift",
        permission: 'purchase-order-menu'
      },
    }]
  }, {
    title: 'Etc',
    child: [{
      childtitle: "Summary",
      childlink: "/inventory/summary",
      permission: 'summary-menu'
    }, {
      childtitle: "Summary Unit",
      childlink: "/inventory/summary-unit",
      permission: "summary-unit-menu"
    }, {
      childtitle: "Transfer",
      childlink: "/inventory/transfer",
      permission: "transfer-menu"
    }, {
      childtitle: "Transfer Unit",
      childlink: "/inventory/transfer-unit",
      permission: "transfer-unit-menu"
    }, {
      childtitle: "Receive Unit",
      childlink: "/inventory/receive-unit",
      permission: "transfer-unit-menu"
    }, {
      childtitle: "Stock Opname",
      childlink: "/inventory/stock-opname",
      permission: "summary-menu"
    }]
  }, {
    title: "Product",
    child: [{
      childtitle: "Product",
      childlink: "/product",
      permission: "product-menu"
    }, {
      childtitle: "COGS",
      childlink: "/product/cost",
      permission: "cogs-menu"
    }, {
      childtitle: "Price List",
      childlink: "/product/price-list",
      permission: "price-list-menu"
    }]
  }]
}, {
  header: "Purchase",
  submenu: [{
    title: 'Purchase',
    child: [{
      childtitle: "Purchase Order",
      childlink: '/purchase/order?view=purchase',
      permission: "purchase-order-menu",
      gridmenu: {
        label: "Purchase Order",
        icon: "heroicons-outline:document-check",
        permission: 'purchase-order-grid-menu'
      },
    }, {
      childtitle: "Purchase",
      childlink: "/purchase",
      permission: "purchase-menu",
      gridmenu: {
        label: "Purchase",
        icon: "heroicons-outline:archive-box",
        permission: 'purchase-grid-menu'
      },
    }, {
      childtitle: "Shipping",
      childlink: "/shipping",
      permission: "shipping-menu",
      gridmenu: {
        label: "Shipping",
        icon: "heroicons-outline:gift",
        permission: 'shipping-grid-menu'
      },
    }, {
      childtitle: "Bill",
      childlink: "/invoice?view=bill",
      permission: "bill-menu",
      gridmenu: {
        label: "Bill",
        icon: "heroicons-outline:banknotes",
        permission: 'bill-grid-menu'
      },
    }, {
      childtitle: "Vendor",
      childlink: "/vendor",
      permission: "vendor-menu",
      gridmenu: {
        label: "Vendor",
        icon: "heroicons-outline:building-storefront",
        permission: 'vendor-grid-menu'
      },
    }, {
      childtitle: "Report",
      childlink: "/purchase/report-outstanding",
      permission: "purchase-report-menu",
      gridmenu: {
        label: "Report",
        icon: "heroicons-outline:book-open",
        permission: 'report-grid-menu'
      },
    }]
  }, {
    title: "Inventory",
    child: [{
      childtitle: "Receive",
      childlink: "/inventory/receipt",
      permission: "receive-menu"
    }, {
      childtitle: "Delivery",
      childlink: "/inventory/delivery",
      permission: "delivery-menu"
    }, {
      childtitle: "Warranty",
      childlink: "/inventory/warranty",
      permission: "warranty-menu"
    }, {
      childtitle: "Scrap",
      childlink: "/inventory/scrap",
      permission: "scrap-menu"
    }, {
      childtitle: "Summary",
      childlink: "/inventory/summary",
      permission: "summary-menu"
    }]
  }, {
    title: "Product",
    child: [{
      childtitle: "Product",
      childlink: "/product",
      permission: "product-menu"
    }, {
      childtitle: "COGS",
      childlink: "/product/cost",
      permission: "cogs-menu"
    }, {
      childtitle: "Price List",
      childlink: "/product/price-list",
      permission: "price-list-menu"
    }]
  }]
}, {
  header: "Sale",
  submenu: [{
    title: 'Sale',
    child: [{
      childtitle: "Quotation",
      childlink: '/sale/order?view=sale',
      permission: "sale-order-menu",
      gridmenu: {
        label: "Quotation",
        icon: "heroicons-outline:newspaper",
      },
    }, {
      childtitle: "Sale",
      childlink: "/sale",
      permission: "sale-menu",
      gridmenu: {
        label: "Sale",
        icon: "heroicons-outline:shopping-bag",
      },
    }, {
      childtitle: "Sale Unit",
      childlink: "/sale/unit",
      permission: "sale-unit-menu",
      gridmenu: {
        label: "Sale Unit",
        icon: "heroicons:rocket-launch",
      },
    }, {
      childtitle: "Sale Document",
      childlink: "/sale/document",
      permission: "sale-document-menu",
      gridmenu: {
        label: "Sale Document",
        icon: "heroicons-outline:document-check",
      },
    }, {
      childtitle: "Document Leasing",
      childlink: "/sale/document-leasing",
      permission: "sale-document-leasing-menu",
      gridmenu: {
        label: "Document Leasing",
        icon: "heroicons-outline:document-text",
      },
    }, {
      childtitle: "Invoice",
      childlink: "/invoice?view=invoice",
      permission: "invoice-menu",
      gridmenu: {
        label: "Invoice",
        icon: "heroicons-outline:ticket",
      },
    }, {
      childtitle: "Customer",
      childlink: "/customer",
      permission: "customer-menu",
      gridmenu: {
        label: "Customer",
        icon: "heroicons-outline:identification",
      },
    }],
  }, {
    title: 'Report',
    child: [{
      childtitle: "Salesman",
      childlink: "/sale/report/salesman",
      permission: "sale-report-salesman-menu"
    }, {
      childtitle: "Supervisor",
      childlink: "/sale/report/supervisor",
      permission: "sale-report-supervisor-menu"
    }, {
      childtitle: "Branch",
      childlink: "/sale/report/branch",
      permission: "sale-report-branch-menu"
    }, {
      childtitle: "Outstanding (AR)",
      childlink: "/sale/report/outstanding",
      permission: null
    }]
  }, {
    title: 'Finance',
    child: [{
      childtitle: "Petty Cash",
      childlink: "/finance/cash?type=cash",
      permission: null
    }]
  }]
}, {
  header: "Invoice",
  submenu: [{
    title: 'Invoice',
    child: [{
      childtitle: "Invoice",
      childlink: "/invoice?view=invoice",
      permission: null,
      gridmenu: {
        label: "Invoice",
        icon: "heroicons-outline:ticket",
      },
    }, {
      childtitle: "Bill",
      childlink: "/invoice?view=bill",
      permission: null,
      gridmenu: {
        label: "Bill",
        icon: "heroicons-outline:banknotes",
      },

    }, {
      childtitle: "AR Report",
      childlink: "/invoice/list-outstanding?view=invoice",
      permission: null,
      gridmenu: {
        label: "AR Report",
        icon: "heroicons:document-currency-dollar",
      },
    }, {
      childtitle: "Retur",
      childlink: "/retur",
      permission: null,
      gridmenu: {
        label: "Retur",
        icon: "heroicons-outline:arrows-right-left",
      },
    }]
  }, {
    title: 'Finance',
    child: [{
      childtitle: "Bank",
      childlink: "/finance/bank?type=bank",
      permission: null,
    },
    {
      childtitle: "Cash",
      childlink: "/finance/cash?type=cash",
      permission: null,
    },]
  }, {
    title: 'Sale',
    child: [{
      childtitle: "Sale",
      childlink: "/sale",
      permission: null
    }]
  }, {
    title: 'Purchase',
    child: [{
      childtitle: "Purchase",
      childlink: "/purchase",
      permission: null
    }]
  }]
}, {
  header: "Finance",
  submenu: [{
    title: 'Finance',
    child: [{
      childtitle: "Chart Of Account",
      childlink: "/finance/chart-of-account",
      permission: null,
      gridmenu: {
        label: "Chart Of Account",
        icon: "heroicons:square-3-stack-3d",
      },
    },
    {
      childtitle: "Journal",
      childlink: "/finance/journal",
      permission: null,
      gridmenu: {
        label: "Journal",
        icon: "heroicons-outline:table-cells",
      },
    },
    {
      childtitle: "Ledger",
      childlink: "/finance/ledger",
      permission: null,
      gridmenu: {
        label: "Ledger",
        icon: "heroicons-outline:bookmark-square",
      },
    },
    {
      childtitle: "Profit & Loss",
      childlink: "/finance/profit-loss",
      permission: null,
      gridmenu: {
        label: "Profit & Loss",
        icon: "heroicons-outline:chart-bar-square",
      },
    },
    {
      childtitle: "Balance Sheet",
      childlink: "/finance/trial-balance",
      permission: null,
      gridmenu: {
        label: "Balance Sheet",
        icon: "heroicons-outline:scale",
      },
    },
    {
      childtitle: "Template",
      childlink: "/finance/journal-template",
      permission: null,
      gridmenu: {
        label: "Template",
        icon: "heroicons-outline:clipboard-document-list",
      },
    },
    {
      childtitle: "Recap Journal",
      childlink: "/finance/recap-journal",
      permission: null,
      gridmenu: {
        label: "Recap Journal",
        icon: "heroicons-outline:book-open",
      },
    },
    {
      childtitle: "Report",
      childlink: "/finance/report",
      permission: null,
      gridmenu: {
        label: "Report",
        icon: "heroicons:presentation-chart-line",
      },
    }]
  }, {
    title: 'Asset',
    child: [{
      childtitle: "Fixed Asset",
      childlink: "/finance/fixed-asset",
      permission: null,
    }, {
      childtitle: "Fixed Asset Type",
      childlink: "/finance/fixed-asset/type",
      permission: null,
    }]
  }, {
    title: 'Cash & Bank',
    child: [{
      childtitle: "Bank",
      childlink: "/finance/bank?type=bank",
      permission: null
    },
    {
      childtitle: "Cash",
      childlink: "/finance/cash?type=cash",
      permission: null
    },
    {
      childtitle: "Transfer",
      childlink: "/finance/cash-bank-transfer",
      permission: null
    }]
  }, {
    title: 'Voucher',
    child: [{
      childtitle: "Voucher",
      childlink: "/finance/voucher",
      permission: null
    },
    {
      childtitle: "Category",
      childlink: "/finance/voucher/category",
      permission: null
    },
    {
      childtitle: "Approval Path",
      childlink: "/finance/voucher/path",
      permission: null
    }]
  }]
}, {
  header: "Workshop",
  submenu: [{
    title: 'Workshop',
    child: [{
      childtitle: "Quotation",
      childlink: '/workshop/quotation?view=workshop',
      permission: null,
      gridmenu: {
        label: "Quotation",
        icon: "heroicons-outline:newspaper",
        permission: null
      }
    }, {
      childtitle: "Order",
      childlink: "/workshop/order?view=order",
      permission: null,
      gridmenu: {
        label: "Order",
        icon: "heroicons-outline:document-check",
        permission: 'order-grid-menu'
      },
    }, {
      childtitle: "Workshop",
      childlink: "/workshop?view=workshop",
      permission: null,
      gridmenu: {
        label: "Workshop",
        icon: "heroicons-outline:wrench-screwdriver",
        permission: null
      },
    }, {
      childtitle: "Monitor",
      childlink: "/workshop/monitoring",
      permission: null,
      gridmenu: {
        label: "Monitor",
        icon: "heroicons-outline:presentation-chart-line",
        permission: null
      },
    }, {
      childtitle: "Unit",
      childlink: "/unit",
      permission: null,
      gridmenu: {
        label: "Unit",
        icon: "heroicons:rocket-launch",
        permission: null
      },
    }, {
      childtitle: "Service Check List",
      childlink: '/service-checklist',
      permission: null,
      gridmenu: {
        label: "Service Check List",
        icon: "heroicons-outline:clipboard-document-check",
        permission: null
      }
    }]
  }, {
    title: "Invoice",
    child: [{
      childtitle: "Invoice",
      childlink: "/invoice?view=invoice",
      permission: null
    }]
  }, {
    title: "Product",
    child: [{
      childtitle: "Product",
      childlink: "/product",
      permission: null
    }, {
      childtitle: "COGS",
      childlink: "/product/cost",
      permission: null
    }, {
      childtitle: "Price List",
      childlink: "/product/price-list",
      permission: null
    }]
  }, {
    title: "Inventory",
    child: [{
      childtitle: "Deliver",
      childlink: "/inventory/delivery",
      permission: null
    }, {
      childtitle: "Warranty",
      childlink: "/inventory/warranty",
      permission: null
    }, {
      childtitle: "Scrap",
      childlink: "/inventory/scrap",
      permission: null
    }, {
      childtitle: "Summary",
      childlink: "/inventory/summary",
      permission: null
    }]
  }]
}, {
  header: "Unit",
  submenu: [{
    title: 'Unit',
    child: [{
      childtitle: "Unit Check",
      childlink: "/unit/check",
      permission: null
    }, {
      childtitle: "Unit Trip",
      childlink: "/unit/trip",
      permission: null
    }, {
      childtitle: "Monitor",
      childlink: "/unit/trip/monitoring",
      permission: null
    }, {
      childtitle: "Customer",
      childlink: "/customer",
      permission: null,
    }, {
      childtitle: "Vendor",
      childlink: "/vendor",
      permission: null
    }]
  }]
}, {
  header: "Product",
  submenu: [{
    title: 'Product',
    child: [{
      childtitle: "Product",
      childlink: "/product",
      permission: null
    }, {
      childtitle: "Price List",
      childlink: "/product/price-list",
      permission: null
    }]
  }]
}]
export const ProfileMenu = [
  {
    label: "Profile",
    icon: "heroicons-outline:user",
    link: "#",
  },
  {
    label: "Chat",
    icon: "heroicons-outline:chat",
    link: "chat",
  },
  {
    label: "Email",
    icon: "heroicons-outline:mail",
    link: "email",
  },
  {
    label: "Todo",
    icon: "heroicons-outline:chip",
    link: "todo",
  },
  {
    label: "Settings",
    icon: "heroicons-outline:cog",
    link: "#",
  },
  {
    label: "Price",
    icon: "heroicons-outline:credit-card",
    link: "pricing",
  },
  {
    label: "Faq",
    icon: "heroicons-outline:information-circle",
    link: "faq",
  },
  {
    label: "Logout",
    icon: "heroicons-outline:login",
    link: "/",
    doit: () => {
    }
  },
];
export const notifications = [
  {
    title: "Your order is placed",
    desc: "Amet minim mollit non deser unt ullamco est sit aliqua.",

    image: "user.png",
    link: "#",
  },
  {
    title: "Congratulations Darlene 🎉",
    desc: "Won the monthly best seller badge",
    unread: true,
    image: "user2.png",
    link: "#",
  },
  {
    title: "Revised Order 👋",
    desc: "Won the monthly best seller badge",

    image: "user3.png",
    link: "#",
  },
  {
    title: "Brooklyn Simmons",
    desc: "Added you to Top Secret Project group...",

    image: "user4.png",
    link: "#",
  },
];
export const message = [
  {
    title: "Wade Warren",
    desc: "Hi! How are you doing?.....",
    active: true,
    hasnotifaction: true,
    notification_count: 1,
    image: "user.png",
    link: "#",
  },
  {
    title: "Savannah Nguyen",
    desc: "Hi! How are you doing?.....",
    active: false,
    hasnotifaction: false,
    image: "user2.png",
    link: "#",
  },
  {
    title: "Ralph Edwards",
    desc: "Hi! How are you doing?.....",
    active: false,
    hasnotifaction: true,
    notification_count: 8,
    image: "user3.png",
    link: "#",
  },
  {
    title: "Cody Fisher",
    desc: "Hi! How are you doing?.....",
    active: true,
    hasnotifaction: false,
    image: "user4.png",
    link: "#",
  },
  {
    title: "Savannah Nguyen",
    desc: "Hi! How are you doing?.....",
    active: false,
    hasnotifaction: false,
    image: "user2.png",
    link: "#",
  },
  {
    title: "Ralph Edwards",
    desc: "Hi! How are you doing?.....",
    active: false,
    hasnotifaction: true,
    notification_count: 8,
    image: "user3.png",
    link: "#",
  },
  {
    title: "Cody Fisher",
    desc: "Hi! How are you doing?.....",
    active: true,
    hasnotifaction: false,
    image: "user4.png",
    link: "#",
  },
];

export const topMenu = [
  {
    isHeader: true,
    title: "DASHBOARD",
  },
  {
    title: "Dashboard",
    icon: "heroicons-outline:home",
    link: "home",
  },
];

// Org Tree preset class
export const orgTreeClass = {
  table: {
    class: ['mx-auto my-0', 'border-spacing-0 border-separate']
  },
  cell: {
    class: ['text-center align-top', 'py-0 px-3']
  },
  node: ({ context }) => ({
    class: [
      'relative inline-block',
      // Spacing
      'py-3 px-4',
      // Shape
      'border',
      'rounded-md',
      'border-surface-200 dark:border-surface-700',
      // Color
      {
        'text-surface-600 dark:text-white/80': !(context != null && context.selected),
        'bg-surface-0 dark:bg-surface-900': !(context != null && context.selected),
        'text-primary-highlight-inverse': context == null ? void 0 : context.selected,
        'bg-primary-highlight': context == null ? void 0 : context.selected
      },
      // States
      {
        'hover:bg-surface-100 dark:hover:bg-surface-800': (context == null ? void 0 : context.selectable) && !(context != null && context.selected),
        'hover:bg-primary-highlight-hover': (context == null ? void 0 : context.selectable) && (context == null ? void 0 : context.selected)
      },
      { 'cursor-pointer': context == null ? void 0 : context.selectable }
    ]
  }),
  linecell: {
    class: ['text-center align-top', 'py-0 px-3']
  },
  linedown: {
    class: ['mx-auto my-0', 'w-px h-[20px]', 'bg-surface-200 dark:bg-surface-700']
  },
  lineleft: ({ context }) => ({
    class: [
      // Alignment
      'text-center align-top',
      // Spacing
      'py-0 px-3',
      // Shape
      'rounded-none border-r',
      { 'border-t': context.lineTop },
      // Color
      'border-surface-200 dark:border-surface-700'
    ]
  }),
  lineright: ({ context }) => ({
    class: [
      // Alignment
      'text-center align-top',
      // Spacing
      'py-0 px-3',
      // Shape
      'rounded-none',
      // Color
      { 'border-t border-surface-200 dark:border-surface-700': context.lineTop }
    ]
  }),
  nodecell: {
    class: 'text-center align-top py-0 px-3'
  },
  nodetoggler: {
    class: ['absolute bottom-[-0.75rem] left-2/4 -ml-3', 'z-20', 'flex items-center justify-center', 'w-6 h-6', 'rounded-full', 'border border-surface-200 dark:border-surface-700', 'bg-inherit text-inherit', 'focus:outline-none focus:outline-offset-0 focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-400', 'cursor-pointer no-underline select-none']
  },
  nodetogglericon: {
    class: ['static inline-block', 'w-4 h-4']
  }
}

export const alertType = [
  {
    type: "dark",
  },
  {
    type: "primary",
  },

  {
    type: "secondary",
  },
  {
    type: "success",
  },
  {
    type: "info",
  },
  {
    type: "danger",
  },
  {
    type: "warning",
  },
];

export const alertType2 = [
  {
    type: "dark-light",
  },
  {
    type: "primary-light",
  },

  {
    type: "secondary-light",
  },
  {
    type: "success-light",
  },
  {
    type: "info-light",
  },
  {
    type: "danger-light",
  },
  {
    type: "warning-light",
  },
];

export const alertType3 = [
  {
    type: "dark",
    icon: "fluent:target-20-regular",
  },
  {
    type: "primary",
    icon: "fluent:target-20-regular",
  },

  {
    type: "secondary",
    icon: "heroicons-outline:support",
  },
  {
    type: "success",
    icon: "akar-icons:double-check",
  },
  {
    type: "t-info",
    icon: "heroicons-outline:information-circle",
  },
  {
    type: "danger",
    icon: "heroicons-outline:exclamation",
  },
  {
    type: "warning",
    icon: "heroicons-outline:ban",
  },
];
export const alertType4 = [
  {
    type: "dark-light",
    icon: "fluent:target-20-regular",
  },
  {
    type: "primary-light",
    icon: "fluent:target-20-regular",
  },

  {
    type: "secondary-light",
    icon: "heroicons-outline:support",
  },
  {
    type: "success-light",
    icon: "akar-icons:double-check",
  },
  {
    type: "info-light",
    icon: "heroicons-outline:information-circle",
  },
  {
    type: "danger-light",
    icon: "heroicons-outline:exclamation",
  },
  {
    type: "warning-light",
    icon: "heroicons-outline:ban",
  },
];
export const alertType5 = [
  {
    type: "dark-outline",
  },
  {
    type: "primary-outline",
  },

  {
    type: "secondary-outline",
  },
  {
    type: "success-outline",
  },
  {
    type: "info-outline",
  },
  {
    type: "danger-outline",
  },
  {
    type: "warning-outline",
  },
];
export const alertType6 = [
  {
    type: "dark-outline",
    icon: "fluent:target-20-regular",
  },
  {
    type: "primary-outline",
    icon: "fluent:target-20-regular",
  },

  {
    type: "secondary-outline",
    icon: "heroicons-outline:support",
  },
  {
    type: "success-outline",
    icon: "akar-icons:double-check",
  },
  {
    type: "info-outline",
    icon: "heroicons-outline:information-circle",
  },
  {
    type: "danger-outline",
    icon: "heroicons-outline:exclamation",
  },
  {
    type: "warning-outline",
    icon: "heroicons-outline:ban",
  },
];

export const trackingParcel = [
  {
    title: "Project start date",
    desc: "This parcel is paid for by the customer. Please contact the customer for any further information.",
    date: "Sep 20, 2021 ",
    time: "12:32 AM",
    status: "ok",
  },
  {
    title: "Project start date",
    date: "Sep 20, 2021 ",
    desc: "This parcel is paid for by the customer. Please contact the customer for any further information.",
    time: "12:32 AM",
    status: "ok",
  },
  {
    title: "Project start date",
    date: "Sep 20, 2021 ",
    desc: "This parcel is paid for by the customer. Please contact the customer for any further information.",
    time: "12:32 AM",
    status: "ok",
  },
  {
    title: "Project start date",
    date: "Sep 20, 2021 ",
    desc: "This parcel is paid for by the customer. Please contact the customer for any further information.",
    time: "12:32 AM",
  },
  {
    title: "Project start date",
    date: "Sep 20, 2021 ",
    desc: "This parcel is paid for by the customer. Please contact the customer for any further information.",
    time: "12:32 AM",
  },
  {
    title: "Project start date",
    date: "Sep 20, 2021 ",
    desc: "This parcel is paid for by the customer. Please contact the customer for any further information.",
    time: "12:32 AM",
  },
  {
    title: "Project start date",
    date: "Sep 20, 2021 ",
    desc: "This parcel is paid for by the customer. Please contact the customer for any further information.",
    time: "12:32 AM",
  },
];

export const TodoList = [
  {
    id: 1,
    image: require("@/assets/images/users/user-1.jpg"),
    title: "Amet minim mollit non deserunt ullam.",
    isDone: false,
  },
  {
    id: 2,
    image: require("@/assets/images/users/user-2.jpg"),
    title: "Amet minim mollit non deserunt ullam.",
    isDone: false,
  },
  {
    id: 3,
    image: require("@/assets/images/users/user-3.jpg"),
    title: "Amet minim mollit non deserunt ullam.",
    isDone: false,
  },
  {
    id: 4,
    image: require("@/assets/images/users/user-4.jpg"),
    title: "Amet minim mollit non deserunt ullam.",
    isDone: false,
  },
  {
    id: 5,
    image: require("@/assets/images/users/user-5.jpg"),
    title: "Amet minim mollit non deserunt ullam.",
    isDone: false,
  },
  {
    id: 6,
    image: require("@/assets/images/users/user-6.jpg"),
    title: "Amet minim mollit non deserunt ullam.",
    isDone: false,
  },
];

//  todo fillter
export const fillters = [
  {
    value: "all",
    name: "My Task",
    icon: "uil:image-v",
  },
  {
    value: "fav",
    name: "Starred",
    icon: "heroicons:star",
  },
  {
    value: "done",
    name: "Completed",
    icon: "heroicons:document-check",
  },
  {
    value: "trash",
    name: "Trash",
    icon: "heroicons:trash",
  },
  {
    name: "Team",
    icon: "heroicons:users",
    value: "team",
  },
  {
    name: "low",
    icon: "heroicons:flag",
    value: "low",
  },
  {
    name: "medium",
    icon: "heroicons:flag",
    value: "medium",
  },
  {
    name: "high",
    icon: "heroicons:flag",
    value: "high",
  },
  {
    name: "update",
    icon: "heroicons:refresh",
    value: "update",
  },
];

export const editCatagory = [
  {
    value: "team",
    label: "team",
  },
  {
    value: "low",
    label: "low",
  },
  {
    value: "medium",
    label: "medium",
  },
  {
    value: "high",
    label: "high",
  },
];

export const projectCatagory = [
  {
    value: "team",
    label: "team",
  },
  {
    value: "low",
    label: "low",
  },
  {
    value: "medium",
    label: "medium",
  },
  {
    value: "high",
    label: "high",
  },
];
export const assignOption = [
  {
    title: "Mahedi Amin",
    image: require("@/assets/images/avatar/av-1.svg"),
  },
  {
    title: "Sovo Haldar",
    image: require("@/assets/images/avatar/av-2.svg"),
  },
  {
    title: "Rakibul Islam",
    image: require("@/assets/images/avatar/av-3.svg"),
  },
  {
    title: "Moni Haldar",
    image: require("@/assets/images/avatar/av-4.svg"),
  },
  {
    title: "Pritom Miha",
    image: require("@/assets/images/avatar/av-5.svg"),
  },
  {
    title: "Simanta Kika",
    image: require("@/assets/images/avatar/av-6.svg"),
  },
];
